<template>
  <carousel
    class="price-cards"
    :per-page="2"
    :per-page-custom="[
      [768, 3],
      [992, 4],
    ]"
    :navigation-enabled="true"
  >
    <slide class="">
      <div class="card relative _darker">
        <div class="card-header">EARLY BIRD</div>
        <hr class="break-line" />
        <div class="card-body">
          <span class="price"><sup class="price-euro">&euro;</sup>40</span>
          <span
            class="card-text"
            v-text="
              _.has(translations, 'galaDinnerOptional')
                ? translations.galaDinnerOptional
                : 'Gala dinner included (optional)'
            "
          ></span>
          <span>&nbsp;</span>
        </div>
      </div>
    </slide>
    <slide class="">
      <div class="card">
        <div class="card-header">REGULAR</div>
        <hr class="break-line" />
        <div class="card-body">
          <span class="price"><sup class="price-euro">&euro;</sup>60</span>
          <span
            class="card-text"
            v-text="
              _.has(translations, 'galaDinnerOptional')
                ? translations.galaDinnerOptional
                : 'Gala dinner included (optional)'
            "
          ></span>
          <span>&nbsp;</span>
        </div>
      </div>
    </slide>
    <slide class="">
      <div class="card">
        <div class="card-header">UNIZG</div>
        <hr class="break-line" />
        <div class="card-body">
          <span class="price"><sup class="price-euro">&euro;</sup>10</span>
        </div>
      </div>
    </slide>
    <slide class="">
      <div class="card">
        <div class="card-header">MEF ZG</div>
        <hr class="break-line" />
        <div class="card-body">
          <span class="price"><sup class="price-euro">&euro;</sup>0</span>
        </div>
      </div>
    </slide>
    <slide class="">
      <div class="card">
        <div class="card-header text-xs">MEF ZG - alumni</div>
        <hr class="break-line" />
        <div class="card-body">
          <span class="price"><sup class="price-euro">&euro;</sup>30</span>
        </div>
      </div>
    </slide>
    <slide class="">
      <div class="card">
        <div
          class="card-header"
          v-text="
            _.has(translations, 'galaDinner')
              ? translations.galaDinner
              : 'GALA VEČERA'
          "
        ></div>
        <hr class="break-line" />
        <div class="card-body">
          <span class="price"><sup class="price-euro">&euro;</sup>25</span>
        </div>
      </div>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  props: {
    translations: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Carousel,
    Slide,
  },
};
</script>
